/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        

        // locatie prijs berekenen
        $('.location select').change(function(){
          console.log($(this).val());
          if($(this).val() == ""){
            console.log('Gelieve eerst een adres in te vullen');
          }else{
            $.ajax({
              type: 'get',
              url: '/kinepro/ajax.php',
              data: {
                distance: true,
                origin: "Beernem",
                destination: $(this).val(),
                sensor: false
              },
              error: function(XMLHttpRequest, textStatus, error){
                console.log('error: ' + textStatus + ' ' + error + " " + XMLHttpRequest);
              },
              success: function(response){
                if(response > 25){
                  cost = ((response - 25) * 0.3).toFixed(2);
                  $('#extra_cost').html('€ '+ cost);
                  $('.vervoerskost').val('€ '+ cost);
                }else{
                  $('#extra_cost').html('gratis');
                  $('.vervoerskost').val('€ 0');
                }
              }
            });
          }
          return false;
        });



        $('#CF5a8ea8902f729_1-row-1').wrap('<fieldset></fieldset>');
        $('<legend>Over uw revalidatie</legend>').insertBefore($('#CF5a8ea8902f729_1-row-1'));
        $('#CF5a8ea8902f729_1-row-2').wrap('<fieldset></fieldset>');
        $('<legend>Duur van het verhuur</legend>').insertBefore($('#CF5a8ea8902f729_1-row-2'));
        $('#CF5a8ea8902f729_1-row-3, #CF5a8ea8902f729_1-row-4, #CF5a8ea8902f729_1-row-5, #CF5a8ea8902f729_1-row-6, #CF5a8ea8902f729_1-row-7').wrapAll('<fieldset></fieldset>');
        $('<legend>Uw persoonsgegevens</legend>').insertBefore($('#CF5a8ea8902f729_1-row-3'));

        $('#CF5a9027cb9115b_1-row-1').wrap('<fieldset></fieldset>');
        $('<legend>Reservatie</legend>').insertBefore($('#CF5a9027cb9115b_1-row-1'));
        $('#CF5a9027cb9115b_1-row-2, #CF5a9027cb9115b_1-row-3').wrapAll('<fieldset></fieldset>');
        $('<legend>Uw persoonsgegevens</legend>').insertBefore($('#CF5a9027cb9115b_1-row-2'));
        $('#CF5a9027cb9115b_1-row-4, #CF5a9027cb9115b_1-row-5').wrapAll('<fieldset></fieldset>');
        $('<legend>Wanneer</legend>').insertBefore($('#CF5a9027cb9115b_1-row-4'));




        $('.galerij').each( function() {
              var $pic     = $(this),
                  getItems = function() {
                      var items = [];
                      $pic.find('figure a').each(function() {
                          var $href   = $(this).attr('href'),
                              $size   = $(this).data('size').split('x'),
                              $width  = $size[0],
                              $height = $size[1];

                          var item = {
                              src : $href,
                              w   : $width,
                              h   : $height
                          }

                          items.push(item);
                      });
                      return items;
                  }

              var items = getItems();

             // console.log(items);


              var $pswp = $('.pswp')[0];


              $pic.on('click', 'figure', function(event) {
                  event.preventDefault();

                  var $index = $(this).index();
                  var options = {
                      index: $index,
                      bgOpacity: 0.7,
                      showHideOpacity: true
                  }

                  // Initialize PhotoSwipe
                  var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                  lightBox.init();
              });


          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
